import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Nuestra dirección</h3>
                            <p>Alameda Manuel Enrique Araujo CC Feria Rosa L 302-G San Salvador - San Salvador</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Número de contacto</h3>
                            <p>Teléfono: <a href="tel:+50325207299">(+503) 2520-7299</a></p>
                            <p>E-mail: <a href="mailto:info@data-seq.com">info@data-seq.com</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Horario de atención </h3>
                            <p>Lunes  - Viernes: 08:00 AM- 18:00 PM</p>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo